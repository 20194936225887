<template>
	<BaseDialog v-model="dialogStore.dialogs.buyPremium.active">
		<template v-slot:text>
			<div v-if="!success">
				<div
					v-if="error"
					class="d-flex flex-column align-center justify-center"
				>
					<v-icon :icon="mdiAlertOutline" size="55" color="orange-darken-1" />
					<h2 class="mt-2">{{ $t('premium.purchaseErrorTitle') }}</h2>
					<span class="my-3 text-medium-emphasis text-center">
						{{ error }}
					</span>

					<v-btn
						v-if="user.coins < config.premiumPrice"
						variant="flat"
						color="green-darken-3"
						class="mx-1 px-7 mt-4 mt-lg-0 rounded-lg"
						@click="dialogStore.openDialog('buyCoins')"
					>
						<span>{{ $t('premium.buyCoins') }}</span>
					</v-btn>
				</div>
				<v-row v-else class="d-flex justify-center">
					<v-col cols="12" lg="9" class="d-flex flex-column">
						<h2 class="mt-2 text-center">{{ $t('premium.advantages') }}</h2>

						<div class="mt-3 d-flex align-center justify-start w-100">
							<v-icon
								:icon="mdiCheckCircleOutline"
								size="30"
								color="green-darken-3"
								class="me-4"
							/>
							<div>
								{{ $t('premium.advPriority') }}
								<div class="text-medium-emphasis text-caption font-italic">
									{{ $t('premium.advPriorityDesc') }}
								</div>
							</div>
						</div>
						<div class="mt-3 d-flex align-center justify-start">
							<v-icon
								:icon="mdiCheckCircleOutline"
								size="30"
								color="green-darken-3"
								class="me-4"
							/>
							<div>
								{{ $t('premium.advEnergy') }}
								<div class="text-medium-emphasis text-caption font-italic">
									{{ $t('premium.advEnergyDesc') }}
								</div>
							</div>
						</div>
						<div class="mt-3 d-flex align-center justify-start">
							<v-icon
								:icon="mdiCheckCircleOutline"
								size="30"
								color="green-darken-3"
								class="me-4"
							/>
							<div>
								{{ $t('premium.advUnlimited') }}
								<div class="text-medium-emphasis text-caption font-italic">
									{{ $t('premium.advUnlimitedDesc') }}
								</div>
							</div>
						</div>
						<v-btn
							v-if="!error"
							variant="flat"
							color="blue-darken-2"
							class="px-7 mx-1 mt-5 rounded-lg"
							:disabled="!buyButtonActive"
							@click="buyPremium()"
						>
							{{ $t('premium.buyButton') }}
							{{ config.premiumPrice }}
							<img
								class="ms-2"
								alt="coins"
								src="/images/coin.png"
								height="15"
							/>
						</v-btn>
						<v-btn
							variant="flat"
							color="green-darken-3"
							class="mx-1 px-7 mt-4 rounded-lg"
							@click="dialogStore.openDialog('buyCoins')"
						>
							{{ $t('premium.buyCoins') }}
						</v-btn>
						<div class="mt-2 d-flex align-center justify-center">
							<div class="mt-3 d-flex align-center justify-center">
								{{ $t('premium.youHaveCoins') }}
								<span class="text-yellow-darken-3 ms-2">{{ user.coins }}</span>
								<img
									class="ms-2"
									alt="coins"
									src="/images/coin.png"
									height="15"
								/>
							</div>
						</div>
					</v-col>
				</v-row>
			</div>
			<div v-else class="d-flex flex-column align-center justify-center">
				<v-icon
					:icon="mdiCheckBold"
					size="55"
					color="green-darken-2"
					class=""
				/>
				<h2 class="my-2">{{ $t('premium.purchaseSuccessTitle') }}</h2>
				<span class="mt-3 text-center text-medium-emphasis">
					{{ $t('premium.purchaseSuccessDesc') }}
				</span>
			</div>
		</template>
		<template v-slot:actions>
			<v-btn
				variant="elevated"
				class="px-12 rounded-lg"
				@click="dialogStore.closeDialog('buyPremium')"
			>
				<span>{{ $t('close') }}</span>
			</v-btn>
		</template>
	</BaseDialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { mdiAlertOutline, mdiCheckBold, mdiCheckCircleOutline } from '@mdi/js'
import config from '../../config'
import { useDialogStore } from '../../stores/dialogStore'
import { useUserStore } from '../../stores/userStore'
import { useApi } from '../../composables/useApi'
import BaseDialog from '../BaseDialog.vue'

const dialogStore = useDialogStore()
const userStore = useUserStore()
const user = computed(() => userStore.user)
const buyButtonActive = ref(true)
const success = ref(false)
const error = ref<string>('')
const uuid = computed(() => dialogStore.dialogs.buyPremium.uuid)
const isLoading = ref(false)

async function buyPremium() {
	if (!uuid.value) return

	buyButtonActive.value = false
	isLoading.value = true

	try {
		const response = await useApi.tests.buyPremium(uuid.value)

		if (response.error) {
			error.value = response.error
		} else {
			success.value = true
			if (user.value) {
				user.value.coins -= config.premiumPrice
			}
			window.dispatchEvent(
				new CustomEvent('premium-purchased', {
					detail: uuid.value,
				})
			)
		}
	} catch (err) {
		error.value = err.message
	} finally {
		buyButtonActive.value = true
		isLoading.value = false
	}
}

watch(
	() => dialogStore.dialogs.buyPremium.active,
	(active) => {
		if (active === true) {
			success.value = false
			buyButtonActive.value = true
			error.value = ''
		}
	}
)
</script>

<style scoped></style>
