<template>
	<BaseDialog v-model="dialogStore.dialogs.error.active">
		<template v-slot:text>
			<h3>{{ $t('error') }}</h3>

			<div class="mt-4 text-body-2 text-red-darken-1">
				{{ getErrorMessage(errorStore.error) }}
			</div>
		</template>
		<template v-slot:actions>
			<v-btn variant="elevated" class="px-10 rounded-lg" @click="closeError()">
				<span>{{ $t('close') }}</span>
			</v-btn>
		</template>
	</BaseDialog>
</template>

<script setup lang="ts">
import { useDialogStore } from '../../stores/dialogStore'
import { useErrorStore } from '../../stores/errorStore'
import BaseDialog from '../BaseDialog.vue'

const dialogStore = useDialogStore()
const errorStore = useErrorStore()

function isServerError(error: any): error is ServerError {
	return error && typeof error === 'object' && 'data' in error
}

function getErrorMessage(error: Error | ServerError | null) {
	if (!error) return null
	if (isServerError(error)) {
		if (error.data.message) {
			return error.data.message
		} else {
			return error.data
		}
	} else if (error.message) {
		return error.message
	} else {
		return error
	}
}

function closeError() {
	dialogStore.closeDialog('error')
	errorStore.setError(null)
}
</script>

<style scoped></style>
