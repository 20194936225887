<template>
	<v-app-bar color="app-bar">
		<v-container class="d-flex align-center">
			<div id="nav" class="d-flex align-center">
				<RouterLink :to="{ path: localePath('/'), force: true }">
					<img
						class="me-4 mt-2 rounded"
						width="85"
						height="25"
						alt="Pheely"
						:src="`/images/logo-${Math.floor(Math.random() * 3) + 1}.png`"
					/>
				</RouterLink>

				<v-btn
					v-if="user"
					variant="text"
					size="small"
					class="me-2 rounded-lg"
					color="app-bar-button"
					:to="{ path: localePath('/my-tests'), force: true }"
					:ripple="false"
				>
					<v-icon :icon="mdiImageCheckOutline" class="me-1"></v-icon>
					<span>{{ $t('nav.myTests') }}</span>
				</v-btn>

				<v-btn
					v-if="user"
					variant="text"
					size="small"
					class="me-2 rounded-lg"
					color="app-bar-button"
					:to="{ path: localePath('/vote'), force: true }"
					:ripple="false"
				>
					<v-icon :icon="mdiCheckAll" class="me-1"></v-icon>
					<span>{{ $t('nav.vote') }}</span>
				</v-btn>
			</div>

			<div class="ms-auto">
				<v-btn
					variant="text"
					size="small"
					class="rounded-lg px-7"
					v-if="!user"
					@click="dialogStore.openDialog('lang')"
				>
					<v-icon :icon="mdiWeb" class="me-1" />
					<span>{{ $t('nav.language') }}</span>
				</v-btn>

				<v-btn
					variant="text"
					size="small"
					class="rounded-lg px-7"
					v-if="!user"
					@click="dialogStore.openDialog('theme')"
				>
					<v-icon :icon="mdiThemeLightDark" class="me-1" />
					<span>{{ $t('nav.theme') }}</span>
				</v-btn>

				<v-btn
					v-if="!user && !webApp?.initData"
					variant="text"
					size="small"
					class="bg-blue-darken-4 ms-4 rounded-lg px-7"
					:to="{ path: localePath('/login'), force: true }"
				>
					<v-icon :icon="mdiLogin" class="me-1" />
					<span>{{ $t('nav.login') }}</span>
				</v-btn>

				<div v-if="user" class="d-flex align-center">
					<div
						class="me-7 d-flex justify-center align-center text-subtitle-2 text-yellow-darken-3"
					>
						{{ user.coins }}
						<img class="ms-2" alt="coins" src="/images/coin.png" height="15" />

						<v-btn
							class="ms-2"
							size="small"
							color="yellow-darken-4"
							@click="dialogStore.openDialog('buyCoins')"
						>
							{{ $t('premium.buyCoins') }}
						</v-btn>
					</div>

					<Energy type="badge" :energy="user.energy" :width="120" :height="20">
						<OneTimeTooltip
							v-if="
								user.gender != null &&
								user.energy > 5 &&
								!user.tooltips?.['energy']
							"
							name="energy"
							:content="$t('energyTooltip')"
							location="bottom center"
							offset="15"
						/>
					</Energy>
					<v-menu>
						<template v-slot:activator="{ props }">
							<v-btn
								:icon="mdiDotsVertical"
								size="small"
								class="ms-2"
								v-bind="props"
							></v-btn>
						</template>

						<UserMenu
							@settings-clicked="dialogStore.openDialog('settings')"
							@theme-clicked="dialogStore.openDialog('theme')"
							@lang-clicked="dialogStore.openDialog('lang')"
							@feedback-clicked="dialogStore.openDialog('feedback')"
							@help-clicked="dialogStore.openDialog('welcome')"
						/>
					</v-menu>
				</div>
			</div>
		</v-container>
	</v-app-bar>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import {
	mdiCheckAll,
	mdiDotsVertical,
	mdiImageCheckOutline,
	mdiLogin,
	mdiThemeLightDark,
	mdiWeb,
} from '@mdi/js'
import { useWebApp } from '../plugins/webApp'
import Energy from '../components/Energy.vue'
import OneTimeTooltip from '../components/OneTimeTooltip.vue'
import UserMenu from '../components/UserMenu.vue'
import { useDialogStore } from '../stores/dialogStore'
import { useUserStore } from '../stores/userStore'
import { localePath } from '../composables/useLanguage'

const user = computed(() => useUserStore().user)
const dialogStore = useDialogStore()
const webApp = useWebApp()
</script>

<style scoped></style>
