<template>
	<BaseDialog v-model="dialogStore.dialogs.dailyQuest.active">
		<template v-slot:text>
			<template v-if="dialogStore.dialogs.dailyQuest.type === 'new'">
				<v-icon
					:icon="mdiClockCheckOutline"
					size="60"
					color="green-darken-2"
				></v-icon>

				<h3 class="mt-4">{{ $t('dailyQuest.newTitle') }}</h3>

				<v-divider class="my-4 mx-lg-8" />
				<div class="text-subtitle-2 my-4">
					{{
						$t('dailyQuest.task', { votes: config.dailyQuest.requiredVotes })
					}}
				</div>

				<div
					v-if="dialogStore.dialogs.dailyQuest.progress"
					class="mt-2 text-subtitle-2 mx-lg-10"
				>
					<v-progress-linear
						:model-value="
							(dialogStore.dialogs.dailyQuest.progress.progress /
								config.dailyQuest.requiredVotes) *
							100
						"
						color="green-darken-4"
						height="12"
						rounded
					></v-progress-linear>
					<div class="mt-1 text-center text-medium-emphasis text-caption">
						{{ dialogStore.dialogs.dailyQuest.progress.progress }}/{{
							config.dailyQuest.requiredVotes
						}}
					</div>
				</div>
				<v-divider class="my-4 mx-lg-8" />

				<h4 class="mt-4 text-caption text-medium-emphasis">
					{{ $t('dailyQuest.reward') }}
				</h4>
				<div class="mt-2 text-h6 d-flex align-center justify-center">
					<span class="font-weight-bold">{{ config.dailyQuest.reward }}</span>
					<img class="ms-2" alt="coins" src="/images/coin.png" height="15" />
					<span class="ms-1 text-capitalize text-yellow-darken-3">{{
						$t('dailyQuest.coins')
					}}</span>
				</div>
			</template>
			<template v-else>
				<v-icon :icon="mdiCheckBold" size="60" color="green-darken-3"></v-icon>
				<h2 class="mt-4">{{ $t('dailyQuest.congratulations') }}</h2>
				<div class="mt-4 text-medium-emphasis text-subtitle-2">
					{{ $t('dailyQuest.rewardMessage') }}
				</div>

				<h3 class="mt-5">{{ $t('dailyQuest.youReceived') }}:</h3>

				<div class="mt-2 text-body-1 d-flex align-center justify-center">
					<span class="font-weight-bold">{{ config.dailyQuest.reward }}</span>
					<img class="ms-2" alt="coins" src="/images/coin.png" height="15" />
					<span class="ms-1 text-capitalize text-yellow-darken-3">{{
						$t('dailyQuest.coins')
					}}</span>
				</div>
			</template>
		</template>
		<template v-slot:actions>
			<v-btn
				variant="elevated"
				color="green-darken-3"
				class="px-8 rounded-lg"
				:autofocus="false"
				@click="dialogStore.closeDialog('dailyQuest')"
			>
				<span>{{ $t('ok') }}</span>
			</v-btn>
		</template>
	</BaseDialog>
</template>

<script setup lang="ts">
import { mdiCheckBold, mdiClockCheckOutline } from '@mdi/js'
import config from '../../config'
import { useDialogStore } from '../../stores/dialogStore'
import BaseDialog from '../BaseDialog.vue'

const dialogStore = useDialogStore()
</script>
