<template>
	<BaseDialog
		v-model="dialogStore.dialogs.settings.active"
		:persistent="isRequired"
	>
		<template v-slot:text>
			<div v-if="Object.keys(errors).length > 0">
				<v-alert color="red-darken-3" class="mb-4 px-4" variant="tonal">
					<div v-for="error in errors">• {{ error }}</div>
				</v-alert>
			</div>

			<div v-if="!user.gender">
				<v-alert
					color="teal-lighten-2 text-subtitle-2"
					class="mb-4 px-4 mt-4 rounded-lg"
					variant="tonal"
				>
					{{ $t('settings.initial') }}
				</v-alert>
				<h3 class="mb-2 d-flex justify-center">
					{{ $t('settings.yourGender') }}:
				</h3>
				<div class="mt-2 mb-4 ms-n2 d-flex justify-center">
					<v-btn
						stacked
						variant="text"
						size="small"
						color="pink-lighten-1"
						class="m-2 rounded-lg"
						:min-width="100"
						:active="settingsData.gender === 1"
						@click="settingsData.gender = 1"
					>
						<v-icon :icon="mdiGenderFemale" class="mb-1"></v-icon>
						<span>{{ $t('settings.yourGenderFemale') }}</span>
					</v-btn>
					<v-btn
						stacked
						variant="text"
						size="small"
						color="blue-accent-2"
						class="m-2 rounded-lg"
						:min-width="100"
						:active="settingsData.gender === 2"
						@click="settingsData.gender = 2"
					>
						<v-icon :icon="mdiGenderMale"></v-icon>
						<span>{{ $t('settings.yourGenderMale') }}</span>
					</v-btn>
				</div>
			</div>

			<h3 class="mb-2 d-flex justify-center">
				{{ $t('settings.votePhotos') }}:
			</h3>
			<div class="mt-2 ms-n2 d-flex justify-center flex-wrap">
				<v-btn
					stacked
					variant="text"
					size="small"
					color="pink-lighten-1"
					class="ma-1 rounded-lg"
					:min-width="120"
					:active="settingsData.voteGender === 1"
					@click="settingsData.voteGender = 1"
				>
					<v-icon :icon="mdiGenderFemale" class="mb-1"></v-icon>
					<span>{{ $t('settings.votePhotosFemale') }}</span>
				</v-btn>
				<v-btn
					stacked
					variant="text"
					size="small"
					color="blue-accent-2"
					class="ma-1 rounded-lg"
					:min-width="120"
					:active="settingsData.voteGender === 2"
					@click="settingsData.voteGender = 2"
				>
					<v-icon :icon="mdiGenderMale"></v-icon>
					<span>{{ $t('settings.votePhotosMale') }}</span>
				</v-btn>
				<v-btn
					stacked
					variant="text"
					size="small"
					color="purple-lighten-1"
					class="ma-1 rounded-lg"
					:min-width="120"
					:active="settingsData.voteGender === 3"
					@click="settingsData.voteGender = 3"
				>
					<v-icon :icon="mdiGenderMaleFemale"></v-icon>
					<span>{{ $t('settings.votePhotosBoth') }}</span>
				</v-btn>
			</div>
		</template>
		<template v-slot:actions>
			<v-btn
				:disabled="!formReady"
				variant="elevated"
				color="green-darken-4"
				class="d-block mx-auto rounded-lg"
				width="180"
				@click="save"
				:loading="isLoading"
			>
				<div class="d-flex">
					<v-icon :icon="mdiCheck" class="me-1"></v-icon>
					<span>{{ $t('save') }}</span>
				</div>
			</v-btn>
		</template>
	</BaseDialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
	mdiCheck,
	mdiGenderFemale,
	mdiGenderMale,
	mdiGenderMaleFemale,
} from '@mdi/js'
import { useDialogStore } from '../../stores/dialogStore'
import { useUserStore } from '../../stores/userStore'
import { useApi } from '../../composables/useApi'
import BaseDialog from '../BaseDialog.vue'

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()
const dialogStore = useDialogStore()
const user = computed(() => userStore.user)

const settingsData = ref<SettingsData>({
	gender: null,
	voteGender: null,
})

const isRequired = computed(
	() =>
		user.value &&
		(user.value.gender === null || user.value.vote_gender === null)
)

const formReady = computed(() =>
	Object.values(settingsData.value).every((x) => x !== null)
)

const errors = ref<string[]>([])
const isLoading = ref(false)

async function save() {
	errors.value = []
	isLoading.value = true

	try {
		const response = await useApi.user.saveSettings(settingsData.value)

		if (response.errors) {
			errors.value = response.errors
		} else {
			dialogStore.closeDialog('settings')
			if (user.value) {
				user.value.gender = settingsData.value.gender
				user.value.vote_gender = settingsData.value.voteGender
			}

			if (route.name === 'vote') {
				router.push({ path: '/vote', force: true })
			}
		}
	} catch (error) {
		errors.value = [error.message]
	} finally {
		isLoading.value = false
	}
}

watch(user, (newUser) => {
	if (newUser) {
		settingsData.value.gender = newUser.gender
		settingsData.value.voteGender = newUser.vote_gender
	} else {
		settingsData.value.gender = null
		settingsData.value.voteGender = null
	}
})

watch(
	() => dialogStore.dialogs.settings.active,
	(newVal) => {
		if (newVal === true) {
			settingsData.value.gender = user.value ? user.value.gender : null
			settingsData.value.voteGender = user.value ? user.value.vote_gender : null
			errors.value = []
		}
	}
)

watch(
	isRequired,
	(val) => {
		if (val === true) dialogStore.openDialog('settings')
	},
	{ immediate: true }
)
</script>

<style scoped></style>
