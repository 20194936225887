<template>
	<BaseDialog v-model="dialogStore.dialogs.theme.active">
		<template v-slot:text>
			<div class="d-flex flex-wrap justify-center">
				<v-btn
					:active="theme.global.name.value == 'light'"
					width="170"
					variant="flat"
					class="main-btn mx-2 my-1 rounded-lg"
					@click="selectTheme('light')"
				>
					<v-icon :icon="mdiLightbulbOn10" size="12" class="me-1"></v-icon>
					<span>{{ $t('settings.themeLight') }}</span>
				</v-btn>
				<v-btn
					:active="theme.global.name.value == 'dark'"
					width="170"
					variant="flat"
					class="main-btn mx-2 my-1 rounded-lg"
					@click="selectTheme('dark')"
				>
					<v-icon :icon="mdiMoonWaningCrescent" size="12" class="me-1"></v-icon>
					<span>{{ $t('settings.themeDark') }}</span>
				</v-btn>
				<v-btn
					:active="theme.global.name.value == 'tg'"
					width="170"
					variant="flat"
					class="main-btn mx-2 my-1 rounded-lg"
					v-if="webApp?.initData"
					@click="selectTheme('tg')"
				>
					<img class="me-1" height="15" src="/images/telegram.webp" />
					<span>{{ $t('settings.themeTg') }}</span>
				</v-btn>
			</div>
		</template>
	</BaseDialog>
</template>

<script lang="ts" setup>
import { useTheme } from 'vuetify'
import { mdiLightbulbOn10, mdiMoonWaningCrescent } from '@mdi/js'
import { useWebApp } from '../../plugins/webApp'
import { useDialogStore } from '../../stores/dialogStore'
import BaseDialog from '../BaseDialog.vue'

const dialogStore = useDialogStore()
const theme = useTheme()
const webApp = useWebApp()
function selectTheme(val: string) {
	dialogStore.closeDialog('theme')
	theme.global.name.value = val
	localStorage.setItem('theme', val)
}
</script>

<style scoped></style>
