import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useWebApp } from '../plugins/webApp'
import { useUserStore } from '../stores/userStore'
import { useApi } from './useApi'

export function usePayment() {
	const { t, locale } = useI18n({ useScope: 'global' })
	const userStore = useUserStore()
	const webApp = useWebApp()

	const paymentRealCurrency = computed(() =>
		locale.value == 'ru' ? 'rub' : 'usd'
	)
	const paymentCurrency = computed(() =>
		webApp?.initData ? 'stars' : paymentRealCurrency.value
	)

	const error = ref<string | null>(null)
	const state = ref(0)
	const coinsReceived = ref(0)
	const currentOrder = ref<{ orderId: number; provider: string } | null>(null)
	const selectedCoinsAmount = ref(0)

	async function getPaymentPacks() {
		try {
			const response = await useApi.payments.getPacks()
			return response.packs
		} catch (e) {
			console.error(e)
			error.value = t('error')
			return null
		}
	}

	async function checkOrder(orderId: number, provider: string) {
		currentOrder.value = {
			orderId,
			provider,
		}
		pollOrderStatus()

		setTimeout(() => {
			if (currentOrder.value) {
				currentOrder.value = null
				error.value = t('orderCheckTimeout')
				state.value = 1
			}
		}, 5 * 60 * 1000)
	}

	async function createOrder(provider: string, amount: number, price: number) {
		try {
			let orderResponse: OrderResponse | null = null

			switch (provider) {
				case 'freekassa':
					orderResponse = await useApi.payments.createFreekassaOrder(
						amount,
						price,
						paymentCurrency.value
					)
					break
				case 'enot':
					orderResponse = await useApi.payments.createEnotOrder(
						amount,
						price,
						paymentCurrency.value
					)
					break
				case 'telegram':
					orderResponse = await useApi.payments.createTelegramOrder(
						amount,
						price,
						paymentCurrency.value
					)
					return orderResponse
				default:
					throw new Error('Invalid provider')
			}

			if (orderResponse?.error) {
				error.value = orderResponse.error
				state.value = 1
				return null
			}

			return orderResponse
		} catch (e) {
			console.error(e)
			error.value = t('error')
			state.value = 1
			return null
		}
	}

	function pollOrderStatus() {
		if (!currentOrder.value) return

		useApi.payments
			.getOrderStatus(currentOrder.value.orderId, currentOrder.value.provider)
			.then((data) => {
				if (data.status === 1) {
					state.value = 4
					coinsReceived.value = data.coins || 0
					if (userStore.user) {
						userStore.user.coins += coinsReceived.value
					}
					currentOrder.value = null
				} else if (data.status === null) {
					error.value = t('error')
					state.value = 1
					currentOrder.value = null
				} else {
					setTimeout(pollOrderStatus, 3000)
				}
			})
			.catch((e) => {
				console.error(e)
				error.value = t('error')
				state.value = 1
				currentOrder.value = null
			})
	}

	function stopPolling() {
		if (currentOrder.value) {
			currentOrder.value = null
		}
	}

	return {
		paymentCurrency,
		error,
		state,
		coinsReceived,
		selectedCoinsAmount,
		createOrder,
		getPaymentPacks,
		checkOrder,
		stopPolling,
	}
}
