import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useDialogStore } from './dialogStore'

export const useErrorStore = defineStore('error', () => {
	const error = ref<Error | ServerError | null>(null)
	const dialogStore = useDialogStore()

	function setError(newError: Error | ServerError | null) {
		error.value = newError
		if (newError) {
			dialogStore.openDialog('error')
		}
	}

	return { error, setError }
})
