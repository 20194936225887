function isBot(userAgent: string): boolean {
	return /bot|crawler|spider|crawling/i.test(userAgent)
}

export function registerServiceWorker(): void {
	if (
		'serviceWorker' in navigator &&
		!isBot(navigator.userAgent) &&
		!window.Telegram
	) {
		window.addEventListener('load', () => {
			setTimeout(async () => {
				try {
					const registrations = await navigator.serviceWorker.getRegistrations()
					for (const registration of registrations) {
						await registration.unregister()
					}

					const registration = await navigator.serviceWorker.register(
						'/sw.js',
						{
							scope: '/',
							type: 'classic',
						}
					)

					registration.addEventListener('updatefound', () => {
						const newWorker = registration.installing
						if (newWorker) {
							newWorker.addEventListener('statechange', () => {
								if (
									newWorker.state === 'installed' &&
									navigator.serviceWorker.controller
								) {
									// Можно показать уведомление о доступном обновлении
								}
							})
						}
					})
				} catch (error) {
					console.error('ServiceWorker registration failed:', error)

					try {
						const cacheNames = await caches.keys()
						await Promise.all(
							cacheNames.map((cacheName) => caches.delete(cacheName))
						)
					} catch (cacheError) {
						console.error('Cache cleanup failed:', cacheError)
					}
				}
			}, 1000)
		})
	}
}
