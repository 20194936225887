import {
	createRouter,
	createWebHistory,
	RouteLocationNormalized,
} from 'vue-router'
import { useUserStore } from './stores/userStore'
import { changeLanguage, type LanguageType } from './composables/useLanguage'
import config from './config'

export const baseRoutes = [
	{
		path: '/',
		name: 'main',
		component: () => import('./pages/main.vue'),
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('./pages/login.vue'),
	},
	{
		path: '/tg-app',
		name: 'tg-app',
		component: () => import('./pages/tg-app.vue'),
	},
	{
		path: '/vote',
		name: 'vote',
		component: () => import('./pages/vote.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/my-tests',
		name: 'my-tests',
		component: () => import('./pages/my-tests.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/create-test',
		name: 'create-test',
		component: () => import('./pages/create-test.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/test/:uuid',
		name: 'test',
		component: () => import('./pages/test.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/privacy',
		name: 'privacy',
		component: () => import('./pages/privacy.vue'),
	},
	{
		path: '/terms',
		name: 'terms',
		component: () => import('./pages/terms.vue'),
	},
	{
		path: '/offer',
		name: 'offer',
		component: () => import('./pages/offer.vue'),
	},
	{
		path: '/payment-success',
		name: 'paymentSuccess',
		component: () => import('./pages/payment-success.vue'),
	},
	{
		path: '/payment-canceled',
		name: 'paymentCanceled',
		component: () => import('./pages/payment-canceled.vue'),
	},
	{
		path: '/logout',
		name: 'logout',
		component: () => import('./pages/logout.vue'),
		meta: { requiresAuth: true },
	},
]

// Создаем функцию для генерации маршрутов с префиксами
function generateLocalizedRoutes(routes: any[]) {
	const localizedRoutes = []

	// Добавляем маршруты с префиксами
	for (const locale of config.i18n.availableLocales) {
		for (const route of routes) {
			const localizedRoute = { ...route }
			localizedRoute.path = `/${locale}${route.path === '/' ? '' : route.path}`
			if (route.name) {
				localizedRoute.name = `${locale}-${route.name}`
			}
			localizedRoutes.push(localizedRoute)
		}
	}

	// Добавляем редирект с корневого маршрута
	localizedRoutes.push({
		path: '/',
		redirect: () => {
			const defaultLocale =
				localStorage.getItem('language') || config.i18n.defaultLocale
			return `/${defaultLocale}`
		},
	})

	// Добавляем catch-all маршрут для неправильных URL
	localizedRoutes.push({
		path: '/:pathMatch(.*)*',
		redirect: (to: RouteLocationNormalized) => {
			const defaultLocale =
				localStorage.getItem('language') || config.i18n.defaultLocale
			return `/${defaultLocale}${to.path}`
		},
	})

	return localizedRoutes
}

const router = createRouter({
	history: createWebHistory(),
	routes: generateLocalizedRoutes(baseRoutes),
})

router.beforeEach(async (to, from, next) => {
	const userStore = useUserStore()

	const isValidLocale = (locale: string): locale is LanguageType => {
		return config.i18n.availableLocales.includes(locale as LanguageType)
	}

	const urlLocale = to.path.split('/')[1]
	if (urlLocale && isValidLocale(urlLocale)) {
		await changeLanguage(urlLocale, false)
	}

	if (!userStore.isInitialized) {
		await userStore.initUser()
	}

	if (to.meta.requiresAuth && !userStore.user) {
		const locale =
			urlLocale && isValidLocale(urlLocale)
				? urlLocale
				: config.i18n.defaultLocale
		next({
			path: `/${locale}/login`,
			query: { redirect: to.fullPath },
		})
		return
	}

	if (to.path === from.path) {
		to.meta.reload = Date.now()
	}

	next()
})

export default router
