<template>
	<BaseDialog v-model="dialogStore.dialogs.buyCoins.active">
		<template v-slot:text>
			<v-alert
				v-if="error"
				color="red-darken-3"
				class="mb-4 px-4 rounded-lg"
				variant="tonal"
			>
				{{ $t('error') }}

				<div v-if="typeof error === 'string'" class="mt-2 text-red-lighten-1">
					{{ error }}
				</div>
			</v-alert>
			<div v-if="state === 0" class="mt-4 mb-5">
				<v-progress-circular
					color="primary"
					indeterminate
				></v-progress-circular>
			</div>
			<div v-else-if="state === 1">
				<h3 class="mb-4">{{ $t('payment.selectCoinsAmount') }}</h3>
				<div class="d-flex flex-wrap justify-center">
					<v-row>
						<v-col v-for="pack in paymentPacks" cols="12" lg="6">
							<v-card
								:hover="false"
								:link="false"
								class="rounded-lg pa-3 elevation-3 position-relative overflow-hidden"
							>
								<div
									v-if="
										getCoinsPrice(pack.coins, false) !==
										getCoinsPrice(pack.coins, true)
									"
									class="discount-corner"
								>
									<div class="discount-text text-h6">
										-{{ getDiscountPercent(pack.coins) }}%
									</div>
								</div>

								<v-card-item>
									<div class="d-flex flex-column align-center">
										<span class="text-caption font-weight-bold">
											{{ $t('payment.coins') }}:
										</span>
										<div class="d-flex align-center mb-2">
											<span
												class="text-h5 font-weight-bold text-yellow-darken-3"
												>{{ pack.coins }}</span
											>
											<img
												class="ms-1"
												alt="coins"
												src="/images/coin.png"
												height="20"
											/>
										</div>

										<span class="text-caption font-weight-bold">
											{{ $t('payment.price') }}:
										</span>
										<div class="d-flex align-center">
											<div
												v-if="
													getCoinsPrice(pack.coins, false) ===
													getCoinsPrice(pack.coins, true)
												"
												class="text-h5 d-flex align-center"
											>
												{{ getCoinsPrice(pack.coins, false) }}
												<v-icon :icon="getCurrencyIcon()" size="20" />
											</div>
											<div v-else class="d-flex align-center">
												<s class="text-body-2 text-disabled me-2">
													{{ getCoinsPrice(pack.coins, false) }}
												</s>
												<span class="text-h5 text-blue-darken-3 ms-auto">
													{{ getCoinsPrice(pack.coins, true) }}
												</span>
												<v-icon :icon="getCurrencyIcon()" size="20" />
											</div>
										</div>

										<v-btn
											size="default"
											color="green-darken-4"
											class="mt-3 px-8 rounded-lg"
											@click="selectCoinAmount(pack.coins)"
										>
											<span>{{ $t('payment.buy') }}</span>
										</v-btn>
									</div>
								</v-card-item>
							</v-card>
						</v-col>
					</v-row>
				</div>

				<div class="mt-4 mb-4 text-center text-caption text-medium-emphasis">
					{{ $t('payment.offer') }}
					<RouterLink
						class="text-decoration-underline"
						:to="{ path: localePath('/offer') }"
						@click="dialogStore.closeDialog('buyCoins')"
					>
						{{ $t('payment.offerLink') }}
					</RouterLink>
				</div>
			</div>
			<div v-else-if="state === 2">
				<div
					class="d-flex align-center text-subtitle-1 justify-center mb-2 mt-2"
				>
					{{ $t('payment.youSelected') }}
					<span class="text-yellow-darken-3 font-weight-bold ms-1">
						{{ selectedCoinsAmount }}
					</span>
					<img class="mx-1" alt="coins" src="/images/coin.png" height="15" />
					{{ $t('payment.for') }}
					<span class="text-green-darken-3 font-weight-bold ms-1">
						{{ getCoinsPrice(selectedCoinsAmount, true) }}
					</span>
					<v-icon :icon="getCurrencyIcon()" size="17" />
				</div>
				<v-btn
					class="mb-6 rounded-lg px-8"
					color="green-darken-4"
					@click="state = 1"
				>
					<span>{{ $t('payment.changeAmount') }}</span>
				</v-btn>
				<h3 class="mb-5">{{ $t('payment.selectPaymentMethod') }}</h3>
				<div class="mb-4 d-flex flex-wrap">
					<div class="flex-1-0-0 px-2 py-2">
						<v-card
							class="rounded-lg pa-4 d-flex align-center justify-center"
							:hover="true"
							:link="true"
							:height="70"
							@click="processPayment('freekassa')"
						>
							<img
								src="/images/freekassa_logo.png"
								height="35"
								class="rounded-lg me-2"
							/>
							<div class="text-h6">FreeKassa</div>
						</v-card>
					</div>
					<div class="flex-1-0-0 px-2 py-2">
						<v-card
							class="rounded-lg pa-4 d-flex align-center justify-center"
							:hover="true"
							:link="true"
							:height="70"
							@click="processPayment('enot')"
						>
							<img
								src="/images/enot_logo.png"
								height="35"
								class="rounded-lg me-2"
							/>
							<div class="text-h6">Enot.io</div>
						</v-card>
					</div>
				</div>
			</div>
			<div v-else-if="state === 3">
				<h3 class="my-3">{{ $t('payment.waitingPayment') }}</h3>
				<v-progress-circular
					color="primary"
					indeterminate
					class="mb-4"
				></v-progress-circular>
			</div>
			<div v-else class="mb-5">
				<v-icon
					:icon="mdiCheckBold"
					size="55"
					color="green-darken-2"
					class=""
				/>
				<h2 class="my-2">{{ $t('premium.purchaseSuccessTitle') }}</h2>
				<span
					class="my-3 text-center text-medium-emphasis d-flex align-center justify-center"
				>
					{{ $t('payment.youReceived') }}
					<span class="text-yellow-darken-3 d-flex align-center ms-1">
						{{ coinsReceived }}
						<img class="mx-1" alt="coins" src="/images/coin.png" height="15" />
					</span>
				</span>
			</div>
			<a
				ref="paymentLink"
				class="d-none"
				target="_blank"
				rel="noopener noreferrer"
			></a>
		</template>
		<template v-slot:actions>
			<v-btn
				variant="elevated"
				class="px-10 rounded-lg"
				@click="dialogStore.closeDialog('buyCoins')"
			>
				<span>{{ $t('close') }}</span>
			</v-btn>
		</template>
	</BaseDialog>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { InvoiceStatuses } from '@twa-dev/types'
import { mdiCheckBold, mdiCurrencyRub, mdiCurrencyUsd, mdiStar } from '@mdi/js'
import { useWebApp } from '../../plugins/webApp'
import { useApi } from '../../composables/useApi'
import { usePayment } from '../../composables/usePayment'
import { useDialogStore } from '../../stores/dialogStore'
import { useUserStore } from '../../stores/userStore'
import BaseDialog from '../BaseDialog.vue'
import { localePath } from '../../composables/useLanguage'
const { t, locale } = useI18n()
const userStore = useUserStore()
const dialogStore = useDialogStore()
const paymentLink = ref<HTMLElement>()
const webApp = useWebApp()
const {
	paymentCurrency,
	error,
	state,
	selectedCoinsAmount,
	coinsReceived,
	getPaymentPacks,
	createOrder,
	checkOrder,
	stopPolling,
} = usePayment()

const paymentPacks = ref<PaymentPacksType | null>(null)

type PaymentPacksType = Array<{
	coins: number
	rub: {
		price: number
		priceFinal: number
	}
	usd: {
		price: number
		priceFinal: number
	}
}>

function getCoinsPrice(coinsAmount: number, finalPrice: boolean) {
	const pack = paymentPacks.value?.find((pack) => pack.coins === coinsAmount)
	if (pack && pack[paymentCurrency.value])
		return finalPrice
			? pack[paymentCurrency.value].priceFinal
			: pack[paymentCurrency.value].price
	return null
}

function getCurrencyIcon() {
	if (paymentCurrency.value == 'stars') return mdiStar
	else if (paymentCurrency.value == 'rub') return mdiCurrencyRub
	else return mdiCurrencyUsd
}

function getDiscountPercent(coinsAmount: number) {
	const pack = paymentPacks.value?.find((pack) => pack.coins === coinsAmount)
	if (pack && pack[paymentCurrency.value]) {
		const originalPrice = pack[paymentCurrency.value].price
		const finalPrice = pack[paymentCurrency.value].priceFinal
		return Math.round(((originalPrice - finalPrice) / originalPrice) * 100)
	}
	return 0
}

async function selectCoinAmount(amount: number) {
	selectedCoinsAmount.value = amount
	if (!webApp?.initData) {
		state.value = 2
	} else {
		await telegramPayment()
	}
}

async function processPayment(provider: string) {
	const orderData = await createOrder(
		provider,
		selectedCoinsAmount.value,
		getCoinsPrice(selectedCoinsAmount.value, true)
	)

	if (orderData) {
		state.value = 3
		paymentLink.value?.setAttribute('href', orderData.url)
		paymentLink.value?.click()
		checkOrder(orderData.orderId, provider)
	}
}

async function openInvoiceCallback(status: InvoiceStatuses) {
	if (status === 'cancelled' || status === 'failed') {
		state.value = 1
		error.value = null
	} else if (status === 'paid') {
		state.value = 4
		coinsReceived.value = selectedCoinsAmount.value
		userStore.user.coins += coinsReceived.value
		dialogStore.openDialog('buyCoins')
	}
}

async function telegramPayment() {
	console.log('telegramPayment')
	const orderData = await createOrder(
		'telegram',
		selectedCoinsAmount.value,
		getCoinsPrice(selectedCoinsAmount.value, true)
	)

	if (orderData) {
		const invoiceResponse = await useApi.payments.createTelegramInvoice(
			orderData.orderId,
			locale.value
		)

		if (invoiceResponse.error) {
			state.value = 1
			error.value = t('error')
		} else {
			state.value = 3
			webApp.openInvoice(invoiceResponse.link, openInvoiceCallback)
		}
	}
}

async function fetchPaymentPacks() {
	state.value = 0
	error.value = null
	paymentPacks.value = await getPaymentPacks()
	if (paymentPacks.value !== null) {
		state.value = 1
	} else {
		error.value = t('error')
	}
}

watch(
	() => dialogStore.dialogs.buyCoins.active,
	async (active) => {
		if (active === true) {
			await fetchPaymentPacks()
		} else {
			stopPolling()
		}
	}
)

onMounted(() => {
	if (dialogStore.dialogs.buyCoins.active) {
		fetchPaymentPacks()
	}
})
</script>

<style scoped>
.position-relative {
	position: relative;
}

.discount-corner {
	position: absolute;
	top: 0;
	right: 0;
	width: 80px;
	height: 80px;
	overflow: hidden;
	background: #126605;
	background: #0a4701;

	transform: translate(40px, -40px) rotate(45deg);
}

.discount-text {
	position: absolute;
	text-align: center;
	width: 100%;
	display: block;
	bottom: 4px;
	font-size: 0.8rem !important;
	color: #ffffff;
	opacity: 1;
}

.overflow-hidden {
	overflow: hidden;
}
</style>
