<template>
	<v-app>
		<template v-if="!userStore.isLoading">
			<AppBar v-if="!$vuetify.display.mobile" />
			<v-main>
				<title>Pheely</title>

				<div class="h-100 d-flex flex-column">
					<Energy
						v-if="user && $vuetify.display.mobile"
						type="line"
						class="px-4 mt-4 rounded-xl"
						:energy="user.energy"
						:height="8"
					>
						<OneTimeTooltip
							v-if="
								user.gender != null &&
								user.energy > 5 &&
								!user.tooltips?.['energy']
							"
							name="energy"
							:content="$t('energyTooltip')"
							location="bottom center"
							offset="15"
						/>
					</Energy>

					<RouterView
						:key="
							$route.meta.reload ? String($route.meta.reload) : $route.fullPath
						"
					/>
				</div>

				<DailyQuestDialog />
				<ErrorDialog />
				<SettingsDialog />
				<ThemeDialog />
				<LangDialog />
				<BuyCoinsDialog />
				<FeedbackDialog />
				<WelcomeDialog />
				<BuyPremiumDialog />
				<FlashMessage />
				<PaymentStatusDialog />
			</v-main>

			<MobileNav v-if="$vuetify.display.mobile" />

			<v-footer
				v-if="!$vuetify.display.mobile"
				app
				color="app-bar"
				class="pa-0 d-flex flex-column"
			>
				<Footer />
			</v-footer>
		</template>
		<div
			v-else
			class="h-100 w-100 d-flex flex-column align-center justify-center"
		>
			<h2 class="mb-7">{{ $t('userLoading') }}</h2>
			<v-progress-circular
				indeterminate
				color="primary"
				size="40"
			></v-progress-circular>
		</div>
	</v-app>
</template>

<script setup lang="ts">
import {
	computed,
	onBeforeMount,
	onMounted,
	onUnmounted,
	nextTick,
	ref,
	watch,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTheme } from 'vuetify'
import { useWebApp, createTelegramTheme } from './plugins/webApp'
import AppBar from './components/AppBar.vue'
import BuyCoinsDialog from './components/dialogs/BuyCoinsDialog.vue'
import BuyPremiumDialog from './components/dialogs/BuyPremiumDialog.vue'
import DailyQuestDialog from './components/dialogs/DailyQuestDialog.vue'
import ErrorDialog from './components/dialogs/ErrorDialog.vue'
import FeedbackDialog from './components/dialogs/FeedbackDialog.vue'
import LangDialog from './components/dialogs/LangDialog.vue'
import PaymentStatusDialog from './components/dialogs/PaymentStatusDialog.vue'
import SettingsDialog from './components/dialogs/SettingsDialog.vue'
import ThemeDialog from './components/dialogs/ThemeDialog.vue'
import WelcomeDialog from './components/dialogs/WelcomeDialog.vue'
import Energy from './components/Energy.vue'
import FlashMessage from './components/FlashMessage.vue'
import Footer from './components/Footer.vue'
import MobileNav from './components/MobileNav.vue'
import OneTimeTooltip from './components/OneTimeTooltip.vue'
import { useAppHead } from './composables/useAppHead'
import {
	changeLanguage,
	localePath,
	type LanguageType,
} from './composables/useLanguage'
import { useDialogStore } from './stores/dialogStore'
import { useUserStore } from './stores/userStore'
import { useApi } from './composables/useApi'
import config from './config'

const router = useRouter()
const dialogStore = useDialogStore()
const userStore = useUserStore()
const user = computed(() => userStore.user)
const theme = useTheme()
const isDailyQuestShown = ref(false)
const webApp = useWebApp()

const handleUnauthorized = () => {
	userStore.clearUserData()
	router.push(localePath('login'))
}

async function checkDailyQuest() {
	try {
		await new Promise((resolve) => setTimeout(resolve, 1000))

		const questResponse = await useApi.quest.getToday()

		if (questResponse.dailyQuest && !questResponse.dailyQuest.completed) {
			dialogStore.openDialog('dailyQuest', {
				type: 'new',
				progress: {
					progress: questResponse.dailyQuest.progress,
					completed: questResponse.dailyQuest.completed,
					claimed: false,
					required: 10,
				},
			})
			isDailyQuestShown.value = true
		}
	} catch (error) {
		console.error(error.message)
	}
}

watch(
	[() => userStore.user, () => isDailyQuestShown.value],
	async ([newUser, isShown]) => {
		if (newUser && !isShown) {
			await checkDailyQuest()
		}
	},
	{ immediate: true }
)

async function initLanguage() {
	const storedLang = localStorage.getItem('language') as LanguageType
	if (storedLang) {
		await changeLanguage(storedLang)
	} else {
		if (userStore.user) {
			await changeLanguage(userStore.user.locale as LanguageType)
		} else {
			const browserLang = navigator.language.split('-')[0] as LanguageType
			await changeLanguage(
				browserLang === 'ru' ? 'ru' : config.i18n.defaultLocale
			)
		}
	}
}

async function initTheme() {
	const themeName = localStorage.getItem('theme')

	if (themeName) {
		theme.global.name.value = themeName
	}

	if (webApp?.initData) {
		createTelegramTheme(theme, webApp)

		if (!themeName) {
			theme.global.name.value = 'tg'
		}
	}
}

onBeforeMount(async () => {
	await initLanguage()
	await initTheme()
})

onMounted(async () => {
	window.addEventListener('unauthorized', handleUnauthorized)

	await nextTick()
	document.getElementById('main-loader')?.remove()
})

onUnmounted(() => {
	window.removeEventListener('unauthorized', handleUnauthorized)
})

useAppHead()
</script>

<style scoped></style>
