import './assets/css/app.scss'
import './assets/css/fonts.scss'

import 'vuetify/styles'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useI18n } from 'vue-i18n'
import { createVuetify, type ThemeDefinition } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { initYandexMetrika } from 'yandex-metrika-vue3'

// Vuetify
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { createHead } from '@unhead/vue'
import { webAppPlugin } from './plugins/webApp'
import App from './app.vue'
import themeData from './data/themeData.json'
import i18n from './i18n'
import router from './router'
import { useErrorStore } from './stores/errorStore'

import { registerServiceWorker } from './register-sw'

const originalConsoleError = console.error

function setupConsoleHandlers() {
	console.error = function (...args: unknown[]) {
		originalConsoleError.apply(console, args)
		handleGlobalError(args[0] as Error)
	}

	window.onerror = function (
		event: Event | string,
		source?: string,
		lineno?: number,
		colno?: number,
		error?: Error
	): void {
		if (
			String(event).includes(
				'ResizeObserver loop completed with undelivered notifications'
			)
		) {
			return
		}
		handleGlobalError(error || new Error(String(event)))
	}

	window.onunhandledrejection = function (event: PromiseRejectionEvent): void {
		handleGlobalError(event.reason as Error)
	}
}

function handleGlobalError(error: Error): void {
	const errorStore = useErrorStore()
	errorStore.setError(error)
}

function createThemes(): {
	themes: Record<string, ThemeDefinition>
	defaultTheme: string
} {
	let themes: Record<string, ThemeDefinition> = themeData
	let defaultTheme = 'dark'

	return { themes, defaultTheme }
}

function createVuetifyInstance(
	themes: Record<string, ThemeDefinition>,
	defaultTheme: string
) {
	return createVuetify({
		icons: {
			defaultSet: 'mdi',
			aliases,
			sets: {
				mdi,
			},
		},
		locale: {
			adapter: createVueI18nAdapter({ i18n, useI18n }),
		},
		theme: {
			defaultTheme: defaultTheme,
			themes: themes,
		},
	})
}

function initializeApp() {
	setupConsoleHandlers()
	const head = createHead()
	const pinia = createPinia()

	const { themes, defaultTheme } = createThemes()
	const vuetify = createVuetifyInstance(themes, defaultTheme)

	const app = createApp(App)
		.use(head)
		.use(i18n)
		.use(vuetify)
		.use(pinia)
		.use(router)
		.use(webAppPlugin)
		.use(initYandexMetrika, {
			id: '98414571',
			router: router,
			env: process.env.NODE_ENV,
			scriptSrc: 'https://mc.yandex.ru/metrika/tag.js',
			options: {
				defer: true,
				clickmap: true,
				trackLinks: true,
				accurateTrackBounce: true,
				webvisor: true,
			},
		})

	app.mount('#app')

	registerServiceWorker()
}
initializeApp()
