<template>
	<BaseDialog v-model="dialogStore.dialogs.feedback.active">
		<template v-slot:text>
			<div v-if="!sended" ref="formContainer">
				<v-alert
					v-if="errors"
					color="red-darken-3"
					class="mb-4 px-4 rounded-lg"
					variant="tonal"
				>
					<div v-for="error in errors" :key="error">
						{{ error }}
					</div>
				</v-alert>

				<h3 class="mb-4">{{ $t('feedback.title') }}</h3>
				<div class="text-body-2 text-medium-emphasis mb-6">
					<i18n-t keypath="feedback.desc" tag="div" scope="global">
						<template v-slot:mail>
							<a href="mailto:feedback@pheely.app" class="text-blue-darken-2">
								feedback@pheely.app
							</a>
						</template>
					</i18n-t>
				</div>

				<v-form ref="form">
					<v-text-field
						v-model="email"
						name="email"
						class="rounded-lg"
						:label="$t('feedback.email')"
						rounded="lg"
						required
					></v-text-field>
					<v-textarea
						v-model="feedback"
						:label="$t('feedback.text')"
						name="feedback"
						variant="filled"
						rounded="lg"
						auto-grow
						counter
						maxlength="2000"
					></v-textarea>
				</v-form>
			</div>
			<div v-else>
				<v-icon :icon="mdiCheckBold" size="55" color="green-darken-2" />
				<h4 class="mt-4 mb-5">{{ $t('feedback.sended') }}</h4>
			</div>
		</template>
		<template v-slot:actions>
			<div class="d-flex flex-column flex-lg-row align-center justify-center">
				<v-btn
					variant="elevated"
					class="mx-2 rounded-lg order-2 order-lg-1"
					:width="200"
					@click="dialogStore.dialogs.feedback.active = false"
				>
					<span>{{ $t('close') }}</span>
				</v-btn>
				<v-btn
					variant="elevated"
					class="mx-2 px-8 rounded-lg order-1 order-lg-2 mb-4 mb-lg-0"
					:width="200"
					color="green-darken-4"
					@click="sendFeedback"
					:loading="isLoading"
				>
					<v-icon :icon="mdiCheckBold" size="16" class="me-1" />
					<span>{{ $t('send') }}</span>
				</v-btn>
			</div>
		</template>
	</BaseDialog>
</template>

<script lang="ts" setup>
import { nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { mdiCheckBold } from '@mdi/js'
import { useDialogStore } from '../../stores/dialogStore'
import { useApi } from '../../composables/useApi'
import BaseDialog from '../BaseDialog.vue'

const dialogStore = useDialogStore()
const { t } = useI18n()
const form = ref()
const formContainer = ref<HTMLElement | null>(null)
const email = ref('')
const feedback = ref('')
const sended = ref(false)
const errors = ref<string[] | null>(null)
const isLoading = ref(false)

function scrollToTop() {
	if (formContainer.value) {
		nextTick(() => {
			formContainer.value?.scrollTo({ top: 0, behavior: 'smooth' })
		})
	}
}

async function sendFeedback() {
	errors.value = null
	isLoading.value = true

	try {
		const response = await useApi.user.sendFeedback(email.value, feedback.value)
		if (response.errors) {
			errors.value = response.errors
			scrollToTop()
		} else if (response.success) {
			sended.value = true
		}
	} catch (error) {
		errors.value = [t('error')]
		scrollToTop()
	} finally {
		isLoading.value = false
	}
}

watch(
	() => dialogStore.dialogs.feedback.active,
	(active) => {
		if (active === true) {
			email.value = ''
			feedback.value = ''
			sended.value = false
			errors.value = null
		}
	}
)
</script>

<style scoped>
.text-center.pa-3 {
	max-height: 70vh;
	overflow-y: auto;
}
</style>
