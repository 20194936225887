<template>
	<BaseDialog v-model="dialogStore.dialogs.lang.active">
		<template v-slot:text>
			<v-btn
				v-for="availableLocale in config.i18n.availableLocales"
				variant="flat"
				:active="availableLocale == locale"
				class="main-btn ma-1"
				@click="selectLang(availableLocale)"
			>
				<img
					height="12"
					class="me-1"
					:alt="locale"
					:src="`/images/flags/${availableLocale}.svg`"
				/>
				{{ $t(`locales.${availableLocale}`) }}
			</v-btn>
		</template>
	</BaseDialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useDialogStore } from '../../stores/dialogStore'
import BaseDialog from '../BaseDialog.vue'
import config from '../../config'
import {
	changeLanguage,
	type LanguageType,
} from '../../composables/useLanguage'

const { locale } = useI18n({ useScope: 'global' })
const dialogStore = useDialogStore()

async function selectLang(lang: LanguageType) {
	dialogStore.closeDialog('lang')
	await changeLanguage(lang)
}
</script>

<style scoped></style>
